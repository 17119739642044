/* header */

.header {
    display: block;
    max-width: 100%;
    padding: 20px 10px;
    margin: 0px auto;
    background-color: red;
  }

  .header .logo {
    padding-left: 10%;
    float: left;
  }
  
  .header .menu li {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    display: inline;
    background-color: red;
  }
  .grey-color {
    color: #868e96;
  }
  
  .header .menu li a {
    display: inline;
    padding: 20px 20px;
    float: right;
    /* color: black; */
    text-decoration: none;
    font-size: 17pt;
  }
  
  
  .header .logo * {
    position: relative;
    width: 300px;
    height: 75px;
    display: block;
    float: left;
    font-size: 1.5em;
    text-decoration: none;
    margin-top: 10px;
    line-height: normal;
  }
  
  /* menu */
  
  .menu {
    font-family: "Google Sans Regular";
    background-color: "white";
  }
  
  .header .menu {
    clear: both;
    max-height: 0;
    transition: max-height 0.2s ease-out;
  }
  
  /* menu icon */
  
  .header .menu-icon {
    cursor: pointer;
    display: inline-block;
    padding: 28px 20px;
    position: relative;
    user-select: none;
  }
  
  .header .menu-icon .navicon {
    background: #333;
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 18px;
  }
  
  .header .menu-icon .navicon:before,
  .header .menu-icon .navicon:after {
    background: #333;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
  }
  
  .header .menu-icon .navicon:before {
    top: 5px;
  }
  
  .header .menu-icon .navicon:after {
    top: -5px;
  }
  
  /* menu btn */
  
  .header .menu-btn {
    display: none;
  }
  
  .header .menu-btn:checked ~ .menu {
    max-height: 370px;
  }
  
  .header .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
  }
  
  .header .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
  }
  
  .header .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
  }
  
  .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
  .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
  }
  
  /* 48em = 768px */
  
  @media (min-width: 48em) {
    .header li {
      float: left;
    }
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .header .menu {
      max-height: none;
    }
    .header .menu-icon {
      display: none;
    }
  }
  @media (max-width: 960px) and (min-width: 768px) {
    .header {
      font-size: 12px;
    }
  }
  