.contact-main {
    width: 100%;
  }

  .schedule-form {
    width: 100%;
    padding: 100px 10px;
    margin: 0px auto;
  }
  
  .schedule-form {
    display: flex;
  }


@media (max-width: 768px) {
    .contact-main {
        display: block;
    }
}