  .gretting-main {
    width: 90%;
    padding: 20px 10px;
    margin: 0px auto;
  }
  
  .greeting-main {
    display: flex;
  }
  
  .greeting-main > * {
    flex: 1;
    margin-bottom: 30px;
  }
  
  .greeting-text {
    margin-top: 80px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 30px;
    line-height: 1.1;
    font-family: "Google Sans Bold";
  }
  
  .greeting-main .greeting-image-div img {
    justify-content: space-around;
    max-width: 90%;
    height: auto;
    padding-right: 10%;
    padding-top: 10%;
  }
  
  /* Media Query */
  @media (max-width: 1380px) {
    .greeting-text {
      font-size: 50px;
    }
    .greeting-text-p {
      font-size: 20px;
    }
  }
  @media (max-width: 768px) {
    .greeting-text {
      font-size: 30px;
      margin-top: 0px;
      text-align: center;
    }
    .greeting-subText {
      font-size: 25px;
      text-align: center;
    }
    .greeting-main {
      display: block;
    }
  
    .portfolio-repo-btn-div {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  