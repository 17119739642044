.first-three {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
    display: table;
    table-layout: fixed;
  }

  .first-three img {
    border: 5px solid black;
    border-radius: 50px;
  }

  .first-three span {
    display: table-cell;
    text-align: center;
  }