/* header */

.footer {
    display: block;
    max-width: 100%;
    padding: 20px 10px;
    margin: 0px auto;
    background-color: black;
    text-align: center;
  }
  
  .footer .legal li {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    display: inline;
    background-color: black;
  }
  
  .footer .legal li a {
    display: inline;
    padding: 20px 20px;
    color: white;
    text-decoration: none;
    font-size: 17pt;
  }

  .footer .legal li a:hover {
    text-decoration: underline;
  }
  